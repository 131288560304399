.topbar_active_package span.close_details_drop {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.687);
    color: #fff;
    font-size: 11px;
    text-align: center;
    line-height: 25px;
    position: absolute;
    right: -10px;
    top: -7px;
    cursor: pointer;
}

.cp{
    cursor: pointer; 
}