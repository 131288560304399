/*
 * dashboard css File
*/

.dashboard {
    /* propertys here */
}

.dash_container {
    padding-top: 10px;
    background: #f0f2f7;
}


.Leftpanel {
    overflow: hidden;
    box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.2);
    background: #fff
}

.leftpanelhead {
    padding: 15px;
    background: url(../../images/left_head_bg.png) right bottom no-repeat #1aa3b9;
}

.prodile_image {
    width: 69px;
    height: 69px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border: 2px solid #fff;
    overflow: hidden;
    flex: 0 0 69px;
    max-width: 69px;
    margin-right: 15px;
}

.profile_desc {
    flex: 0 0 auto;
    color: #fff
}

.profile_desc h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 3px
}

.profile_desc h6 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 3px
}

.profile_desc h5 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 3px
}

.member_since {
    flex: 0 0 auto;
    font-size: 14px;
    font-weight: 400;
    margin: 5px 0 0;
}

.leftpanelbody {    
    padding: 15px 0px;
    margin-top: -15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.leftpanelbody .side_panel_nav {
    background: none;
    padding: 0;
}

.leftpanelbody .side_panel_nav .nav-item .nav-link {
    padding: 18px 26px;
    color:#4d4d4d;
    font-weight: 600
}

.left_panel_nav {
    list-style: none;
    padding-left: 0;
    width: 100%
}

.left_panel_nav li {
    padding: 0;
    position: relative;
    overflow: hidden;
}
.left_panel_nav li svg {
    fill: #3c4349;
    margin-right: 10px;
}
.left_panel_nav .card-body{
padding: .5rem 0rem .5rem 1.8rem;
}

.left_panel_nav li:after {
    border-bottom: 1px solid #d7d7d7;
    content: "";
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
}

.left_panel_nav li:last-child:after {
    content: none;
}

.left_panel_nav li a {
    color: #3c4349;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}

.left_panel_nav li a svg {
    fill: #3c4349;
    margin-right: 10px;
}

.left_panel_nav li a.active, .left_panel_nav li a:active, .left_panel_nav li a:hover, .left_panel_nav li a:focus {
    color: #22c3dd !important;
}

.left_panel_nav li a.active svg, .left_panel_nav li a:active svg, .left_panel_nav li a:hover svg, .left_panel_nav li a:focus svg {
    fill: #22c3dd
}

.card {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}


.donation_left {
    flex: 1 1 auto;
    padding: 25px
}

.donation_card h3 { font-size: 20px; color:#48535b; font-weight: 700; margin-bottom: 15px; }

.donation_card strong {
    font-size: 16px;
    font-weight: 600;
    color: #48535b
}

.donation_card p {
    font-size: 15px;
    font-weight: 400;
    color: #48535b;
    margin-bottom: 10px;
}

.donation_card .progress {
    height: .5rem;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    margin: 15px 0 20px
}

.donation_card .progress-bar {
    background-color: #1aa3b9;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}



.sponsorship_block h6 {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
    color: #1aa3b9;
}

.sponsorship_block strong {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    color: #1aa3b9;
}

.sponsorship_block strong span {color:#000}


.donation_block {
    text-align: right;
    color: #48535b
}

.donation_block h6 {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
    color: #48535b;
    text-align: right;
}

.donation_block strong {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    color: #48535b;
    text-align: right;
}

.donation_right {
    flex: 0 0 256px;
    border-left: 1px solid #e9eff3;
    padding: 25px
}

.donation_right_top h2 {
    font-size: 23px;
    font-weight: 700;
    text-align: center;
    color: #20313d;
    margin-bottom: 0
}

.donation_right_top h2 span {
    font-weight: 700;
    font-size: 23px;
    color: #20313d
}
.redemption_code strong {
    font-size: 16px;
    font-weight: 600;
    color: #48535b;
    margin-bottom: 20px;
}

.r_code {
    background: #f5f3ff;
    border: 1px solid #e7e5f1;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #000;
    text-align: center;
    line-height: 25px;
    padding: 7px 34px;
    margin: 0;
    flex:1 1 auto;
    font-weight: 600;
}

.counter {
    width: 86px;
    height: 86px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 32, 84, .2);
    border: 3px solid #1aa3b9;
    text-align: center;
    line-height: 74px;
    font-size: 40px;
    font-weight: 600;
    color: #1aa3b9;
    margin-bottom: 15px;
}

.amt {text-shadow: 3px 3px 5px #b1ebf6; font-weight: 600;}

.peope_ben_count p {
    text-align: center;
    font-size: 14px;
}

.sponsorship_list {
    font-size: 15px;
    color: #1aa3b9;
    text-decoration: none;
}

.sponsorship_list:hover {
    text-decoration: none;
}

@media screen and (max-width:767px) {
    .donation_card .card .d-flex {
        flex-direction: column;
    }

    .donation_right {
        border-left: 0;
        border-top: 1px solid #e9eff3;
    }

    .dash_body .card {
        margin-right: 0 !important;
    }

    .progress_val, .progress_val .donation_block, .progress_val .donation_block h6  {
        text-align: center;
    }

}

.pimage
{
    max-height: 100px;
}


.progress
{
    background-color: #65e41e !important;
}







.profile_box {
    background: #fff;
    width: 80%;
    height: 200px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 30px auto 60px;
    position: relative;
    box-shadow: 0 20px 17px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile_box:before {
    content: "";
    width: 90%;
    height: 8px;
    background: rgba(255, 255, 255, .9);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    bottom: -8px;
    left: 5%;
    z-index: 1;
    box-shadow: 0 2px 3px #c6c6c61a;
}

.profile_box:after {
    content: "";
    width: 80%;
    height: 16px;
    background: #f6f6f7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    bottom: -16px;
    left: 10%;
}

.profile_box .profile_img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #3EB2C5;
    overflow: hidden;
}

.profile_box h5 {
    margin: 8px 0 0;
    font-weight: 400;
}

.profile_box h6 {margin-bottom: 0}


.dash_body .card {
    position: relative;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    overflow: hidden;
    /* box-shadow: 0 3px 15px #00bbd448; */
    box-shadow: 0 0.3125rem 0.3125rem 0 #00bbd448;
    background: #fff;
    position: relative;
    border:5px solid rgb(146 221 234 / 20%);
}

.dash_body .card:before {
    background: #1cc1de;
    /* background: var(--primary-dark); */
    border-radius: 50%;
    bottom: -100px;
    content: "";
    height: 199px;
    left: -94px;
    opacity: .08;
    position: absolute;
    width: 188px;
}

.dash_body .card:after {
    background: #00bcd4;
    /* background: var(--primary-dark); */
    border-radius: 50%;
    content: "";
    height: 266px;
    right: -125px;
    opacity: .06;
    position: absolute;
    top: -133px;
    width: 251px;
}


/* .dash_body .card:after {
    content: "";
    top: -1px;
    right: -1px;
    border-top: 50px solid #eff3fc;
    border-left: 50px solid transparent;
    position: absolute;
    right: 0;
    top: 0
} */
.textwrap{
    word-break: break-word;
}

#showDatetime .react-datetime-picker__inputGroup input {
    background-color: transparent;
    border: 0 none;
    box-shadow: none;
}
#showDatetime .react-datetime-picker__wrapper {
    background-color: #fff;
    border-color: #dcdcdc;
    border-radius: 4px;
    padding: 0px 10px;
}
.react-datetime-picker__inputGroup__divider {
    padding: 1px 2px !important;
    display: inline-block;
}

#showDatetime .react-datetime-picker__wrapper button, .tabform .react-calendar button { border: none; background: none; width: auto; height: auto; box-shadow: none;}
.tabform .react-calendar button:hover, .tabform .react-calendar button:focus, #showDatetime .react-datetime-picker__wrapper button:hover, #showDatetime .react-datetime-picker__wrapper button:focus {background: #e6e6e6 !important;}

#showDatetime .react-datetime-picker__wrapper select {border: none !important; width: auto; height: auto; box-shadow: none;}
#showDatetime .react-datetime-picker__wrapper { padding: 9px 13px; box-shadow: 0 0 10px rgb(0 32 84 / 9%) }

.full_width_container {
    transition: all .5s ease-in-out;
} 

.gen_code_block, .copy_Code_block { flex: 1 1 auto}

.tdiv {min-height:20px}


@media screen and (max-width:767px) {
.mob_up {order:-1; margin-bottom: 15px;}
.gen_code_block {margin-right: 0 !important;}

.full_width_container { padding-left: 5px !important; padding-right: 5px !important; }
.body_card {padding-left: 5px !important; padding-right: 5px !important;}

}


.tooltip-block-redeem-list img{
    margin-left: 20px;
}



.dmOeOm {
    background: #ebfcff30; 
    margin-bottom: 30px; 
    padding: 15px; 
    border-radius: 5px !important; 
    box-shadow: 0 0 20px rgba(63, 63, 63, 0.129);
    top: 15px;
    overflow: inherit !important;
}

.rdt_TableHead .rdt_TableHeadRow { background:#d8f9ff !important; border-radius: 5px; margin-bottom: 5px; }






/* three dots dropdown */
.rdt_TableCell div:first-child {
    overflow: visible !important;
    white-space: normal !important;
}
.three_dotss .drop_partts button:after{
    content: none;
}

.three_dotss .drop_partts .dropdown-menu{
    width: auto;
    min-width: 80px;
    padding: 10px;
    background: #ffff;
    border-radius: 0;
    z-index: 9;
}

.three_dotss .drop_partts .dropdown-menu a{
    padding: 0px;
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #000000;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.three_dotss .drop_partts .dropdown-menu a button {
    height: auto;
}

.three_dotss .drop_partts .dropdown-menu a img{
    margin-right: 12px;
}

.three_dotss .drop_partts .dropdown-menu .dropdown-item:focus, .three_dotss .drop_partts .dropdown-menu .dropdown-item:hover, .three_dotss .drop_partts .dropdown-menu .dropdown-item.active, .dropdown-item:active{
    background: transparent;
    color: rgb(0, 0, 0);
}
.drop_partts .btn{
    display: block;
    width: 100%;
    font-size: 12px;
}
.table_search {
    font-size: 16px; 
    font-weight: 600; 
    margin-right:0 !important;
    clear: both;
    margin-top: 25px !important;
    line-height: 40px;
}

.table_search input[type="text"] {
    margin-left: 5px;
}

.tabpanelOneForm .tabform.body_form select {width: 100%; height: 50px;}

/* .tabpanelOneForm {font-weight: 600; margin-top: 30px;} */
.table_search input[type="text"], .tabpanelOneForm .tabform input[type="date"], .tabpanelOneForm .tabform select {
    width: 250px;
    height: 50px;
    padding: 5px 15px;
    border: 1px solid rgb(188, 188, 188);
    border-radius: 5px;
    box-shadow: 0 10px 25px rgb(147 221 234 / 50%);
    border-left: 3px solid #1aa3b9 !important;
    /* margin-left: 5px; */
}
.table_search input[type="text"]:focus, .tabpanelOneForm input[type="date"]:focus, .tabpanelOneForm select:focus {
    outline: none;
    box-shadow: 0 10px 25px rgba(102, 177, 190, 0.617);
}
.tabpanelOneForm button.btn-primary { 
    height: 46px; 
    width: 100%; background: #213C53; 
    border: none;
    color: #fff;
 }
 .tabpanelOneForm button.btn-primary.download_btn_size{ 
    height: auto; 
 }

 .tabpanelOneForm .tabform a.btn-primary { height: 50px; 
    width: 100%; background: #213C53; 
    border: none;
    line-height: 35px;
 }

.tabpanelOneForm button.btn-primary:hover, .tabpanelOneForm button.btn-primary:focus { 
    background: #2b5379 !important ;  border: none !important; box-shadow: none !important; }

.tabpanelOneForm .tabform a.btn-primary:hover, .tabpanelOneForm .tabform a.btn-primary:focus { 
    background: #2b5379 !important ;  box-shadow: none !important; }   
    
.tabform button {
     /* height: 46px; 
     width: 100%;
     background: #213C53;
     border: none; 
     box-shadow: 0 5px 3px rgb(0 0 0 / 40%);  */
     }
/* .tabform button:hover, .tabform button:focus { 
    background: #2b5379 !important ; 
    border: none !important; box-shadow: none !important; color:#fff } */


.three_dotss .drop_partts .dropdown-menu {z-index: 999;}
/* .col-md-3 {
    max-width: 27%;
} */



.rdt_Table .dropdown .dropdown-menu {padding: 0; min-width: 120px;}
.rdt_Table .dropdown .dropdown-menu .dropdown-item, .rdt_Table .dropdown .dropdown-menu .dropdown-item a {
    margin-bottom: 0;
    display: block;
    
}

.rdt_Table .dropdown .dropdown-menu .dropdown-item button {
    border-radius: 0;
    background: none;
    border: none;
    color: #00BCD4;
    border-bottom: 1px solid #213C53;
    margin-left: 0 !important;
}

.rdt_Table .dropdown .dropdown-menu .dropdown-item i { margin-right: 5px;}


.rdt_Table .dropdown .dropdown-menu .dropdown-item button:hover {
    background: #213C53 ;
    color:#fff
}

.rdt_Table .dropdown .dropdown-menu .dropdown-item:last-child button {border-bottom: none;}

.cong_purchaced {cursor: pointer;}


.tabform .modal-header .close {width: 40px; height:40px; border-radius: 50px; background: red; line-height: 40px; padding: 0; right:-5px; top: -5px; position: absolute;}
.tabform .modal-header .close span {line-height: 40px;}

.tabform .modal-header .close:hover {background: red !important; color:#fff}
.tabpanelOneForm .btnList{
    /* flex:0 0 24%; */
    padding: 0 .5%;
    margin-top: 5px;


}
.tabpanelOneForm .btnList .btn.ghost_button{
    margin-left: 5px;
    /* width: 100%;*/
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 10px;
}
.tabpanelOneForm .btnList:nth-child(1) .btn.ghost_button{
    margin-left: 0;
}
.tabpanelOneForm .btnList:nth-child(5) .btn.ghost_button{
    margin-left: 0;
}



.modal-content {
    /* box-shadow: 0 0 30px #00BCD4;  */
    /* border:12px solid rgb(170 170 170 / 42%); */
    border-radius: 40px;
}

.referral_made {padding: 10px 15px; background: #213C53; color:#fff; margin-left: 10px; border-radius: 10px;}

.reg_block_head {position: relative; margin-bottom: 30px;}
.reg_block_head:after {content:""; position:absolute; top:calc(100% + 5px); left:0; border-bottom: 2px solid #00BCD4; width: calc(100%);}


.verify_field {position: relative; }
.verify_field input[type="text"] {padding-right: 60px;}
.verify_icon {position: absolute; right: 0px; top: 0; height: 100%; width: 40px;}
.verify_icon i { font-size: 30px; color:green}

.congra_head h2 {font-size: 2.3em; font-weight: 600; text-align: left;}
.congra_head h3 {font-size: 20px; font-weight: 600; color: #213c53; text-align: left;}
.congra_head p {font-size: 16px; font-weight: 400; color: #213c53; text-align: left; margin-bottom: 0;}


/* .search_btn {flex: 0 0 60px} */
.search_block {
    background: #fff; 
    border-radius: 15px;
    flex: 0 0 auto;
    display: flex;
}

.search_block span {
    /* flex: 1 1 106px;
    min-width: 80px; */
}

.link_type_style{
    color: #1aa3b9;
    
}
.link_type_style:hover{
    color: #1aa3b9;
    text-decoration: underline;
    cursor: pointer
}
