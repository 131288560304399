
/********** flying man start ************/
.logo {
    position: relative;
    min-width: 24%;
  }
  
   .main_div{
      width: 100%;
      height: 100%;
      left: 0%;
      top: 0%;
      overflow: hidden;
      position: fixed;
      z-index: 999999;
      display: none;
  }
  
  .main_div3{
      left: 95%;
      top: 28px;
      overflow: hidden;
      position: absolute;
      /*z-index: 99999999;*/
      display: none;
      transition: all 5s ease-in;
  }
  .fm img{
      width: 200px;
  }
  
  .rm_stop_gif {
      position: absolute;
      left: 95%;
      width: 85px;
      top: 28px;
      display: none;
      transition: all 2s ease-in;
  }
  
  .rm_stop_gif img {
      max-width: 100%;
      max-height: 65px;
  }
  
  @-webkit-keyframes fly4 {
    
      0% {
          left: 0%;
          bottom: 0%;
          transform: scale(1) rotateY(180deg) rotateZ(336deg);
      }
  
      50% {
          left: 50%;
          bottom: 50%;
          transform: scale(0.6) rotateY(180deg) rotateZ(336deg);
      }
  
      100% {
          left: 100%;
          bottom: 100%;
          transform: scale(0.4) rotateY(180deg) rotateZ(336deg);
      }
  }
  
  @keyframes fly4 {
      0% {
          left: 0%;
          bottom: 0%;
          transform: scale(1) rotateY(180deg) rotateZ(336deg);
      }
  
      50% {
          left: 50%;
          bottom: 50%;
          transform: scale(0.6) rotateY(180deg) rotateZ(336deg);
      }
  
      100% {
          left: 100%;
          bottom: 100%;
          transform: scale(0.4) rotateY(180deg) rotateZ(336deg);
      }
  }
  
  .flying_man4 {
      position: absolute;
      -webkit-animation: fly4 5s linear;
      -moz-animation: fly4 5s linear;
      animation: fly4 5s linear;
  }
  
  @-webkit-keyframes flystop {
    
      0% {
          right: 0%;
          bottom: 0%;
          transform: scale(1) rotateY(180deg);
          opacity: 1;
      }
  
      50% {
          right: 50%;
          bottom: 50%;
          transform: scale(0.6) rotateY(180deg);
          opacity: 1;
      }
  
      80% {
          opacity: .6;
      }
      90% {
          opacity: .4;
      }
  
      100% {
          right: 110%;
          bottom: 100%;
          transform: scale(.33) rotateY(180deg);
          opacity: 0;
      }
  }
  
  @keyframes flystop {
      0% {
          right: 0%;
          bottom: 0%;
          transform: scale(1) rotateY(180deg);
          opacity: 1;
      }
  
      50% {
          right: 50%;
          bottom: 50%;
          transform: scale(0.6) rotateY(180deg);
          opacity: 1;
      }
  
      80% {
          opacity: .6;
      }
      90% {
          opacity: .4;
      }
  
      100% {
          right: 110%;
          bottom: 100%;
          transform: scale(.33) rotateY(180deg);
          opacity: 0;
      }
  }
  
  .flying_man_stop {
      position: absolute;
      -webkit-animation: flystop 3s linear;
      -moz-animation: flystop 3s linear;
      animation: flystop 3s linear;
  }