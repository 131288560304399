/*
 * header css File
*/

/* .header {
    box-shadow: 0 0px 15px rgba(0, 0, 0, .03);
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    width: 100%;
    z-index: 9;
    top: 0;
}

*/

.layout_row {
    position: relative;
}

.left_panel_inner {
    transition: all 0s ease-in-out;
    left: 0;
    min-height:calc(100vh );
    z-index: 999;
}

.right_panel { min-height: calc(100vh - 58px);}




.left_panel {
    background: #93ddea;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    /* width: 100%; */
    margin-right: 36px;
    height: 100%;
    /* overflow-y: scroll; */
}


.left_panel_inner.collupsed {
    left: -25%;
    left: calc(-25% - -36px);
    position: absolute;
    height: 100%;
}

.closemenu {
    position: absolute;
    left: calc(100% - 36px);
    top: 44px;
    width: 30px;
    height: 40px;
    background: #93ddea;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 999;
}

.closemenu img {
    fill: #fff;
    width: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}



@media screen and (min-width: 768px) {
    .left_panel_inner.collupsed {
        left: calc(-33.333% - -36px);
    }
}

@media screen and (min-width: 992px) {
    .left_panel_inner.collupsed {
        left: calc(-25% - -36px);
    }
}


@media screen and (max-width: 767px) {
    .left_panel {
        z-index: 9999;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        /* margin-right: 50%; */
    }

    .left_panel_inner.collupsed {
        left: -80%
    }

    .left_panel_inner {
        position: absolute;
        width: 80%;
        height: 100%;
        top: 0;
        bottom: 0;
        min-height: calc(100vh);
        /* overflow-y: scroll; */
    }

    .right_panel { min-height: calc(100vh - 80px);}

    .closemenu {
        left: calc(100%);
    }

}



.brand_logo {
    padding: 30px 0;
    width: 190px;
    margin: 0 auto;
}

.profile_block {
    background: #b1ebf6;
}

.profile_block .profile_img {
    border-radius: 50px;
    width: 40px;
    height: 40px;
    overflow: hidden;
}


.user_info img {
    max-width: 40px;
}

.user_info h5 {
    color: #000;
    font-size: 18px;
    margin-bottom: 0;
}

.user_info span {
    color: #000;
    font-size: 12px;
    margin-bottom: 0;
}

.user_info h6 {
    color: #000;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 400 !important;
}

.user_info .member_since {
    color: #000;
    margin-top: 0
}

.left_panel .navbar {
    padding: 20px 0;
}

.left_panel .navbar .nav-item {
    flex: 0 0 100%;
}

.left_panel .navbar-nav .nav-link {
    padding-left: 20px;
    padding-right: 26px;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
}

.left_panel .navbar-nav .nav-link svg {margin-right: 5px;}


.left_panel .navbar-nav .nav-link.active,
.left_panel .navbar-nav .nav-link:active,
.left_panel .navbar-nav .nav-link:hover,
.left_panel .navbar-nav .nav-link:focus {
    color: #fff;
}

.left_panel .navbar-nav .nav-item {
    padding: 8px 0;
}

.left_panel .navbar-nav .nav-item:has(.nav-link.active),
.left_panel .navbar-nav .nav-item:hover {
    background: rgb(118, 192, 205);
    background: linear-gradient(90deg, rgba(118, 192, 205, 1) 0%, rgba(118, 192, 205, 0.06348476890756305) 100%);
}



.btn_header {
    border: 1px solid #ffffff;
    background: #1aa3b9 !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    font-size: 13px;
    color: #ffffff;
    padding: 8px 23px;
    font-weight: 500;
    text-transform: uppercase;
}

.btn_header:hover,
.btn_header:active,
.btn_header:focus,
.btn_header.active {
    background: #000 !important;
    color: #fff !important;
    border: 1px solid #22c3dd;

}


.navbar-nav .accordion:hover {
    cursor: pointer;
    background: rgb(118, 192, 205);
    background: linear-gradient(90deg, rgba(118, 192, 205, 1) 0%, rgba(118, 192, 205, 0.06348476890756305) 100%);
    color:#fff
}

.navbar-nav .accordion span:hover, .navbar-nav .accordion span:focus {
    color: #fff;
}

.navbar-nav .accordion li {padding: 8px 0;}
.navbar-nav .collapse .card-body {padding-top: 0px; padding-bottom: 0px;}