.bundle_package_partts{
    margin: 30px 0;
}

 .current_active_box {
    border: 2px solid #0180A4;
    padding: 20px;
    border-radius: 5px;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    overflow: hidden;

    background: #fff;
    box-shadow: 0 0 15px #00bcd4;
    
}

.current_active_box:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(../../../Cmponents/Jobseeker/images/overlayyy.jpg);
    background-position: center left;
    background-size: cover;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.bundle_package_inner {
    border: 2px solid #213c53;
    padding: 20px;
    border-radius: 5px;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    overflow: hidden;

    background: #fff;
    box-shadow: 0 0 15px #5996cb;
    
}

.bundle_package_inner {
    background: rgb(89,150,203);
    background: linear-gradient(180deg, rgba(89,150,203,1) 0%, rgba(33,60,83,1) 100%);
    color: #fff;
}
.bundle_package_inner .logobox_inner_text h2 {color:#fff}

.upgradelogos {background: #fff; border-radius: 10px; padding: 0 5px;}


.bundle_package_partts_head {font-size: 22px;}


.logo_box_inner_img{
    background: #fff;
    padding: 10px 15px;
    border-radius: 15px;
    width: 140px!important;
    margin-right: 20px;
    margin-left: 0;
    box-shadow: 0 0 20px #00000026;
    min-height: 87px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo_box_inner_img img{
    height: 60px;
}

.logobox_inner_text{
    max-width: 82%;
    width: 100%;
}

.bundle_package_top {
    cursor: pointer;
}

.bundle_package_top:hover {
    background: rgb(46,82,113);
    background: linear-gradient(8deg, rgba(46,82,113,1) 0%, rgba(186,247,255,1) 100%);
}

.logobox_inner_text h2{
    color: #213C53;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0px;
}
.logobox_inner_text p{
    margin-bottom: 0px;
    font-size: 18px;
}

.logobox_inner_text p span {font-weight: 600; font-size: 22px; }

.loginbuynow_but{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.loginbuynow_but a.buynowbut{
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    line-height: 40px;
    color: #FFFFFF;
    background-color: #00BCD4;
    border-style: solid;
    border-width: 0px 0px 0px 4px;
    border-color: #000000;
    border-radius: 5px 5px 5px 5px;
    display: inline-block;
    padding: 0 30px;
}

.loginbuynow_but p{
    font-weight: 600;
    color: #000;
    font-size: 18px;
}


@media screen and (max-width:767px) {
    .bundle_package_inner {justify-content: center;}
    .logo_box_inner_img { margin: 0 auto;}
    .logobox_inner_text {max-width: 100%; margin: 15px 0;}
    .upgradelogos {flex:0 0 100%;}
    .upgradelogos .items {flex: 0 0 24.333%;}

    .logos_upgrade_block {flex-wrap: wrap;}
    .bundle_package_inner .add_account {margin-left: inherit; margin-top: 30px;}
}



.icon_logo { background: #fff;
    padding: 5px;
    border-radius: 15px;
    box-shadow: 0 5px 10px #c7c7c7;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.icon_logo img {max-width: 100%; max-height: 70px;}