.step_away{
    margin-top: 68px;
    background: #0E5684;
    border-radius: 30px;
    padding: 65px 35px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.step_away:before{
    content: "";
    position: absolute;
    width: 438px;
    height: 367px;
    background: #22C3DD;
    filter: blur(175px);
    left: 0;
    top: 0;
    z-index: -1;
}

.step_away:after{
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 511px;
    height: 523px;
    background: rgba(233,236,91,0.4);
    filter: blur(175px);
    z-index: -1;
}

.step_away h2{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    text-decoration-line: underline;
    color: #FEFFFF;
    max-width: 910px;
    width: 100%;
    margin: 0 auto;
}

.resume_scoring{
    display: flex;
    justify-content: space-between;
    margin-top: 75px;
}

.scoring_left{
    display: flex;
}

.scoring_right_text{
    max-width: 448px;
    width: 100%;
    margin-left: 20px;
}

.scoring_right_text h3{
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.scoring_right_text p{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
}

.scoring_right .purchase_now{
    background: #22C3DD;
    box-shadow: 0px 4px 6px #032B44;
    border-radius: 8px;
    padding: 16px 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #0E5684;
}
.scoring_right .purchase_now:hover{
    text-decoration: none;
}
.scoring_right .purchase_now span{
    padding-left: 10px;
}


.limited_time_offer{
    padding: 150px 0 125px 0;
    position: relative;
    z-index: 1;
}

.limited_time_offer:after{
    content: "";
    background:#fff url(../../images/lim_offer.png) no-repeat left top;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 419px;
    background-blend-mode: luminosity;
    z-index: -1;
    opacity: 0.2;
}

.limited_time_inner h2{
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
    color: #08456B;
}

.limited_time_inner p{
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #6D6D6D;
    margin-bottom: 20px;
}
.limited_time_inner p span{
    font-size: 24px;
    color: #1AA3B9;
    font-weight: 700;
}

.purchase_packages a{
    border: 1px solid #1AA3B9;
    border-radius: 8px;
    padding: 0px 35px;
    height: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 50px;
    text-transform: uppercase;
    color: #1AA3B9;
    display: inline-block;
    margin-top: 20px;
}


.srv_bundle{
    padding-top: 85px;
}

.serv_bundle_inner h3{
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #08456B;
    margin-bottom: 30px;
}

.serv_bundle_inner h2{
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: #1AA3B9;
}

.survice_bundle_listing{
    background: linear-gradient(180deg, #FFFFFF 0%, #F0F9FF 100%);
    padding: 65px 36px;
}

.surv_bundle_inner_listing{
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
}

.surv_bund_inner_inner_listing{
    padding: 0 15px;
    max-width: calc(100%/4);
    width: 100%;
    margin-bottom: 40px;
    
}
.surv_build_innr_partts{
    position: relative;
    background: #90dfec;
    box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.25);
    border-radius: 10px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 30px;
    height: 100%;
}
.surv_build_innr_partts:before{
    content: "";
    position: absolute;
    background: url(../../images/back_before.png) no-repeat;
    left: 0;
    top: 0;
    width: 100%;
    height: 390px;
    z-index: -1;
}

.surv_center{
    background: #FFFFFF;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.12);
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 30px auto;
    border-radius: 100%;
}

.surv_bund_inner_inner_listing p{
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #212121;
    min-height: 122px;
    margin-bottom: 20px;
}
.surv_bund_inner_inner_listing label{
    display:block;
    text-align: center;
    margin: 0 auto;
}
.surv_bund_inner_inner_listing label strike{
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    /* text-decoration-line: line-through; */
    color: rgba(14, 86, 132, 0.53);
}

.surv_bund_inner_inner_listing label span{
    color: #08456B;
    font-size: 24px;
    margin-left: 15px;
    display: inline-block;
    font-weight: 600;
}

.learn_more{
    text-align: center;
    margin-top: 25px;
}

.learn_more a{
    height: 40px;
    border: 2px solid #0B6DAC;
    border-radius: 6px;
    line-height: 40px;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #0B6DAC;
    padding: 0 20px;
    display: inline-block;
}

.surv_build_innr_partts h2{
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    padding-top: 100px;
    padding-bottom: 30px;
}

.learn_more.pur_pack a{
    background: #0B6DAC;
border: 2px solid #0B6DAC;
border-radius: 6px;
height: 40px;
line-height: 40px;
padding: 0 30px;
font-weight: 600;
font-size: 15px;
text-transform: uppercase;
color: #FFFFFF;
}

@media screen and (max-width:1199px){
    .surv_bund_inner_inner_listing{
        max-width: calc(100%/3);
    }
}

@media screen and (max-width:991px){
    .step_away h2{
        font-size: 18px;
        line-height: 30px;
    }

    .resume_scoring{
        margin-top: 30px;
        flex-wrap: wrap;
    }

    .scoring_right{
        width: 100%;
        text-align: center;
    }

    .purchase_right img{
        max-width: 100%;
        height: auto;
    }
    .limited_time_offer{
        padding: 40px 0;
    }
    .srv_bundle{
        padding-top: 40px;
    }
    .serv_bundle_inner h3{
        font-size: 18px;
        line-height: 24px;
    }
    .surv_bund_inner_inner_listing {
        max-width: calc(100%/2);
    }
}

@media screen and (max-width:575px){
    .surv_bund_inner_inner_listing{
        max-width: calc(100%/1);
    }
    .scoring_left{
        flex-wrap: wrap;
    }
    .scoring_right_text h3{
        font-size: 16px;
    }
    .learn_more.pur_pack a{
        font-size: 12px;
    }
}