@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

a {
  color: #1aa3b9;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f4f7;
  font-size: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}





.btn-main {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  background: #213C53;
  transition: all 0.3s ease;
  box-shadow: 0 5px 3px rgba(0,0,0,.4);
  border-radius: 5px;
  border: 1px solid #213C53;
  text-transform: uppercase;
}
.btn-main:hover,
.btn-main:focus,
.btn-main:active,
.tabpanelOneForm .btn.ghost_button:hover,
.tabpanelOneForm .btn.ghost_button:focus, 
.tabpanelOneForm .btn.ghost_button.active {
  background: #213C53 !important;
  color: #fff !important;
  border-color: #213C53 !important;  
}
.btn-main:hover,
.btn-main:focus,
.btn-main:active {
  box-shadow: 0 5px 3px rgba(0,0,0,.4) !important;
}
.tabpanelOneForm .btn.ghost_button:hover,
.tabpanelOneForm .btn.ghost_button:focus, 
.tabpanelOneForm .btn.ghost_button:active,
.tabpanelOneForm .btn.ghost_button.active {
  box-shadow: none !important;
}

.tabpanelOneForm .btn.ghost_button {
  background: transparent;
  border: 1px solid #213C53;
  color: #213C53;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 10px 20px;
  transition: all .3s ease;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 2px;
  min-width: 220px;
}
.badge_area {
  padding: 0.8rem 1.4rem;
  border-bottom: 1px solid #f0f2f7;
  display: flex;
  flex-wrap: wrap;
}
.logo_item {
  flex: 0 0 10%;
  max-width: 10%;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  padding: 0 0.5rem;
}
.sponsor_logo {
  /* height: 100%; */
  padding: 0.5rem;
  border: 1px solid #f0f2f7;
  border-radius: 0.8rem;
  text-align: center;
  
}
.sponsor_logo img {
  height: 3rem;
  object-fit: contain;
  object-position: center;
}
.logo_inactive.sponsor_logo {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.app_header .nav-link {
  padding: 0 1rem;
  color: #000;
  transition: color .3s ease;
}
.app_header .nav-link:hover,.app_header .nav-link.active {
  color: #1aa3b9;
}
.auto_list{
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 999;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 1px 16px rgb(0 0 0 / 18%);
}
.auto_list > div{
  padding: .5rem 1rem;
  
}
.auto_list > div:not(:last-child){
  border-bottom: 1px solid rgb(239, 235, 235);
}
.auto_list > div:hover{
  background-color: #93ddea;
}
.auto_address{
  position: relative;
}

/* stripe form */
#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}


button.tabformbutton.btn.btn-primary.btn_total {
  background: #213C53;
  border: none;
  line-height: 31px;
}

button.tabformbutton.btn.btn-primary.btn_total:hover, button.tabformbutton.btn.btn-primary.btn_total:focus {
  background: #2b5379 !important;
  box-shadow: none !important;
}

a.tabformbutton.btn.btn-primary.btn_total {
  background: #213C53;
  border: none;
  line-height: 31px;
}

a.tabformbutton.btn.btn-primary.btn_total:hover, a.tabformbutton.btn.btn-primary.btn_total:focus {
  background: #2b5379 !important;
  box-shadow: none !important;
}


.cr_trans_custom {
  border-radius: 50px;
  background: #1aa3b9;
  border: none;
  font-size: 16px;
  padding: 7px 41px;
  margin-top: 15px;
  color: #fff;
}

.cr_trans_custom:hover {
  background: #0c7080;
  text-decoration: none;
  color: #fff;
}

.details_drop_custom {
  /* position: absolute;
  z-index: 99999;
  min-width: 315px;
  left: 0;
  top: 100%;
  display: flex;
  align-items: center; */
  background: #c0f1fa;
  /* border: 1px solid #b7b7b7;
  border-radius: 10px;
  box-shadow: 0 15px 40px rgb(0 0 0 / 15%); */
  height: 280px;
  padding: 34px;
  /* padding: 15px 15px;
  transition: opacity .5s ease-in-out;
  flex-direction: column; */
}

.eTvlwW{
  overflow:inherit !important;
 }
.sc-hGPAah{
  margin-top:4rem !important;
}

.tabpanelOneForm .tabform select.PhoneInputCountrySelect {
  width: 50px !important;
}

.mb_datepicker_new{
  width: 25%;
  background-color: #fff;
}


