/*
 * login css File
*/

.login {
    /* propertys here */
}


.banner_container {
    padding-top: 173px;
    padding-bottom: 80px;
}


.login_box {
    background: #fff;
    min-height: 150px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 19px rgba(3, 59, 95, 0.08);
    overflow:hidden;
}

.loginForm {padding:40px 60px; position: relative;}
.loginForm h3 {font-size: 30px; color:#3c4349; font-weight:800; margin-bottom: 50px; }
.login_button { margin-top: 20px; width: 300px; height:45px }
.loginForm input[type="email"], .loginForm input[type="password"] {height:42px; border:1px solid #d7e2ea;}
.loginForm input[type="email"]::placeholder, .loginForm input[type="password"]::placeholder { color:#7b8c98; font-size: 15px;}
.loginForm .form-check-label, .loginForm .forgotPass { font-size: 14px; color:#3c4349; text-decoration: none; }

.log__bnr {
    background: url('../../images/career.jpg') left top;
    background-size: cover;
}



.left_arrow{
  height: 12px;
  width: 12px;
}
.img-fluid.logo_size{
   height: 70px;
   /* width: 100px; */
}









*, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* user-select: none; */
  }
  
  /* Generic */
  /* body {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    background-color: #ecf0f3;
    color: #a0a5a8;
  } */
  
  /**/
  .main {
    position: relative;
    /* width: 1000px;
    min-width: 1000px; */
    /* min-height: 600px; */

    /* height: 600px; */
    /* padding: 25px 25px; */
    background-color: #ecf0f3;
    /* box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9; */
    box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.23);
    border-radius: 12px;
    overflow: hidden;
    margin: 20px 0;
  }

  
  .container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute;
    top: 0; */
    width: 500px;
    height: 100%;
    padding: 25px;
    background-color: #ffffff;
    transition: 1.25s;
  }
  
  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  /* .form__icon {
    object-fit: contain;
    width: 30px;
    margin: 0 5px;
    opacity: 0.5;
    transition: 0.15s;
  }
  .form__icon:hover {
    opacity: 1;
    transition: 0.15s;
    cursor: pointer;
  } */
  .form__input {
    width: 300px;
    max-width: 100%;
    height: 50px;
    margin: 4px 0;
    padding-left: 25px;
    font-size: 13px;
    letter-spacing: 0.15px;
    border: none;
    outline: none;
    font-family: "Montserrat", sans-serif;
    background-color: #ecf0f3;
    transition: 0.25s ease;
    border-radius: 8px;
    box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
  }
  .form__input:focus {
    box-shadow: inset 4px 4px 4px #d1d9e6, inset -4px -4px 4px #f9f9f9;
  }
  .form__span {
    margin-top: 30px;
    margin-bottom: 12px;
  }
  .form__link {
    color: #181818;
    font-size: 15px;
    margin-top: 25px;
    border-bottom: 1px solid #a0a5a8;
    line-height: 2;
  }
  
  .title {
    font-size: 34px;
    font-weight: 700;
    line-height: 3;
    color: #ffffff;
  }

  .title_form {
    font-size: 34px;
    font-weight: 700;
    line-height: 2;
    color: #00bcd4;
    text-shadow: 2px 5px 5px rgb(212, 212, 212);
  }
  
  .description {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.25px;
    text-align: center;
    line-height: 1.6;
    color:#00bcd4;
    margin-top: 20px;
    text-shadow: 2px 5px 5px rgb(212, 212, 212);
  }
  
  .button {
    width: 180px;
    height: 50px;
    border-radius: 25px;
    margin-top: 50px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1.15px;
    background-color: #4B70E2;
    color: #f9f9f9;
    box-shadow: 8px 8px 16px #d1d9e6, -8px -8px 16px #f9f9f9;
    border: none;
    outline: none;
  }
  
  /**/
  .a-container {
    /* z-index: 100; */
    left: calc(100% - 600px );
  }
  
  /* .b-container {
    left: calc(100% - 600px );
    z-index: 0;
  } */
  
  .switch {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /*top: 0;
    left: 0; */
    height: 100%;
    width: 400px;
    padding: 50px;
    /* z-index: 200; */
    transition: 1.25s;
    background-color: #ecf0f3;
    overflow: hidden;
    box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
    background: url(../../images/bg.jpg) center center no-repeat;
    background-size: cover;
  }
  .switch__circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background-color: rgba(26, 164, 185, 0.155);
    box-shadow: inset 8px 8px 12px rgba(0, 221, 255, 0.5), inset -8px -8px 12px rgba(0, 221, 255, 0.7);
    bottom: -60%;
    left: -60%;
    transition: 1.25s;
    z-index: 1;
  }
  .switch__circle--t {
    top: -30%;
    left: 60%;
    width: 300px;
    height: 300px;
  }
  .switch__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    width: 400px;
    padding: 50px 55px;
    transition: 1.25s;

    background-color: rgba(26, 164, 185, 0.78);
    width: 100%;
    height: 100%;
    color: rgb(255, 255, 255);
    font-weight: 600;
  }
  .switch__button {
    cursor: pointer;
  }
  .switch__button:hover {
    box-shadow: 6px 6px 10px #d1d9e6, -6px -6px 10px #f9f9f9;
    transform: scale(0.985);
    transition: 0.25s;
  }
  .switch__button:active, .switch__button:focus {
    box-shadow: 2px 2px 6px #d1d9e6, -2px -2px 6px #f9f9f9;
    transform: scale(0.97);
    transition: 0.25s;
  }
  
  /**/
  .is-txr {
    left: calc(100% - 400px );
    transition: 1.25s;
    transform-origin: left;
  }
  
  .is-txl {
    left: 0;
    transition: 1.25s;
    transform-origin: right;
  }
  
  .is-z200 {
    z-index: 200;
    transition: 1.25s;
  }
  
  .is-hidden {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: 1.25s;
  }
  
  .is-gx {
    animation: is-gx 1.25s;
  }
  
  @keyframes is-gx {
    0%, 10%, 100% {
      width: 400px;
    }
    30%, 50% {
      width: 500px;
    }
  }

  .login_page{
    background-image: url(../../images/login_bg.jpeg); 
    background-repeat: no-repeat; 
    background-position: center bottom; 
    background-size: cover;
    background-color: transparent !important;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  .flogos {background: #fff; padding: 10px;}
  .flogos .items, .upgradelogos .items { flex: 0 0 8.333%; display: flex; align-items: center;}
  .flogos .items img, .upgradelogos .items img {max-width: 100%; height: auto; max-height: 62px; margin: 0 auto; display: block;}
  .upgradelogos {flex: 1 1 auto;}

  .login-top-bar  {
    box-shadow: 0 0px 15px rgb(0 0 0 / 3%);
    background: rgb(132 132 132 / 80%);
    /* position: absolute; */
    width: 100%;
    z-index: 9;
    top: 0;
    /* height: 85px; */
}

.login-top-bar .top_logos { flex:0 0 20%; display: flex; align-items: center; position: relative; }
.login-top-bar .top_logos img {max-height:83px; display: block; margin: 0 auto; max-width: 50%;}

.tooltips {
  position: absolute; 
  top: calc(100% + 50px); 
  width: 250px; 
  min-height:120px; 
  text-align:center;
  background:#181818d8;
  color:#fff;
  padding: 15px;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 0px 0px 5px rgb(255 255 255 / 23%);
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: all .01s ease-in-out;
}

.login-top-bar .top_logos:hover .tooltips {
  visibility: visible;
  opacity: 1; }







  @media screen and (min-width:768px) and (max-width:1199px) {
    .loginForm h3 {margin-bottom:20px}
    .login_button {margin-top:20px}
}

@media screen and (max-width:380px) {
.keep_login_forgot_pass {flex-direction:column}
.forgotPass {margin-left:0 !important}
}


    /* @media (max-width: 1200px) {
    .main {
      transform: scale(0.7);
    }
  }
  @media (max-width: 1000px) {
    .main {
      transform: scale(0.6);
    }
  } */
  @media (max-width: 800px) {
    .main {
      /* transform: scale(0.5); */
      flex-direction: column;
      height: auto !important;
      min-height: auto !important;
    }
    .switch{ width: 100% !important; min-height: 400px; }
    .switch__circle.left { left: calc(100% - 20%) !important; position: absolute; }
    .container-login {width: 100% !important; height: auto !important;}


    .login-top-bar .top_logos { position: static; }
    .login-top-bar {position: relative;}


  }

  /* @media (max-width: 600px) {
    .main {
      transform: scale(0.4);
    }
  } */