/*
 * companylanding css File
*/

.companylanding {
    /* propertys here */
}

.landinghead {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
}

.companyheadrinfo {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background: #fff;
    min-height: 300px;
    margin-bottom: 30px;
}

.com_desc h4 {
    font-size: 20px;
    font-weight: 600;
    color: #3e454b;
    margin-bottom: 0;
}

.com_desc p {
    font-size: 15px;
    font-style: italic;
    color: #72787d;
    margin-bottom: 0;
}

.location_icon {
    flex: 0 0 30px;
}

.location_desc p {
    font-size: 15px;
    color: #72787d;
    margin-bottom: 0;
}

.donationIcon {
    flex: 0 0 79px;
    width: 79px;
    height: 79px;
    background: #fff;
    border: 5px solid #fff;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px; box-shadow:0 18px 18px rgba(0,22,36,.09);
}


.donationamt strong { font-size: 48px; color:#20313d; font-weight: 700; text-align: center; display: block; margin-bottom: 0; line-height: 30px; margin-top: 15px; }
.donationamt strong span {font-size: 23px; color:#20313d; margin-bottom: 0; }

.people_got { flex: 0 0 79px;
    width: 79px;
    height: 79px;
    background: #fff;
    border: 3px solid #5238da;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px; box-shadow:0 18px 18px rgba(0,22,36,.09);
    color:#5238da;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    line-height: 70px;
}

.people_got_benifit p {text-align: center; font-size: 18px; color:#48535b; margin-top: 15px;}

.companyheadrinfo .col-md-4:nth-child(2) {border-left: 1px solid #e4eaee; border-right: 1px solid #e4eaee;}

.companydesc h4 { font-size: 20px; color:#48535b; font-weight: 600;}
.companydesc p { font-size: 15px; color:#48535b; font-weight: 500;}









.Getsponsorfromarea h2 {
    font-size: 40px;
    color: #232323;
    font-weight: 600;
}

.sponsorfrom {
    background: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 19px rgba(3, 59, 95, 0.15);
}

.form_block {padding: 15px;}

.navTab .nav-item {margin-right:20px; margin-bottom: 5px}
.navTab .nav-item a svg {fill:#61686e; margin-right:10px}
.navTab .nav-item a svg { fill:#8a959d; }
.navTab .nav-item a {background:#fff; border:1px solid #8a959d; color:#8a959d; padding:10px 9px; font-size: 13px; font-weight: 600; box-shadow: 0 0 10px rgba(0,32,84,.09)}

.navTab .nav-item a.active svg {fill:#1aa3b9;}
.navTab .nav-item a.active {
    background: #f8f7ff;
    border: 1px solid #1aa3b9;
    color: #1aa3b9;
}
.tabform .form-group { margin-bottom:.8rem; }
.tabform .form-group label { margin-bottom:3px; font-weight: 600; }
.tabform input[type="text"], .tabform input[type="email"], .tabform select, .tabform .custom-file label {height:50px; box-shadow: 0 0 10px rgba(0,32,84,.09); border: 1px solid #d7e2ea; line-height: 34px}
.tabform .custom-file label {color:#7b8c98}
.tabform .custom-file-label::after {height:100%; line-height:34px; width:130px; text-align: center; }
.tabform textarea {box-shadow: 0 0 10px rgba(0,32,84,.09); resize: none; border: 1px solid #d7e2ea;}

.tabform input[type="text"]::placeholder, .tabform input[type="email"]::placeholder, .tabform textarea::placeholder { color:#7b8c98; font-size: 15px; }


/* .tabformLeft {border-right:1px solid rgba(0,32,84,.09)} */

.tabform h5 {font-weight: 600; font-size: 18; color:#3e454b;}


.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.form-row > .col, .form-row > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
}


.companysponsor h2 {
    font-size: 20px; color:#48535b; font-weight: 600; margin-top: 30px;
}


@media screen and (min-width:768px) and (max-width:991px) {
    .navTab .nav-item a {padding:10px 18px;}
}

@media screen and (max-width:767px) {
    .navTab .nav-item a {padding:10px 18px;}
    /* .tabformLeft {padding-right: 0 !important; border: none;}
    .tabformRight {padding-left: 0 !important; } */
}

@media screen and (max-width:534px) {
    .navTab .nav-item {width:100%; margin-bottom: 5px;}
}