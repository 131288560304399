.upload_file_area input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
}
.upload_file_label {
    margin-bottom: 1.5rem;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #f4f9ff;
    padding: 1rem;
    cursor: pointer;
}
.upload_file_area {
    padding: 3rem 1rem;
}
.upload_info {
    margin-bottom: 2.5rem;
}
.upload_info p {
    font-size: .8rem;
    color: #545454;
}
.upload_info h4 {
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
    font-weight: 600;
    color: #2d333a;
}
@media (min-width: 576px) {
    .modal_upload .modal-dialog {
        max-width: 360px;
    }
}

.modal_upload .modal-content{
 border-radius: 0px;
}

.modal_upload .modal-dialog {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
}

@media (min-width: 576px) {
    .modal_upload .modal-dialog {
        min-height: calc(100% - 3.5rem);
    }
}
