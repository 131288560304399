/*
 * header css File
*/

.header {
    /* propertys here */
}



/* .listitem {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
} */
.listitem {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* left: 0; */
    /* right: 0; */
    /* top: 0; */
    /* bottom: 0; */
    width: 300px;
    margin: 0 auto;
    position: relative;
    padding: 70px 0;
}

.listitem:after {
    position: absolute;
    content: "";
    width: 1px;
    top: 0;
    bottom: 0;
    background: #c6c6c6;
    left: 19px;
    z-index: 1;
}


.listitem li:nth-child(1) {
    margin: 0 0 250px;
}


.listitem li a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border: 1px solid rgb(233, 233, 233);
    height: 200px;
    width: 200px;
    background: #fff;
}


.listitem li a .img_round {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    box-shadow: 0 8px 9px #63848a;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;
}



.listitem li a:after {
    content: "\2713";
    width: 20px;
    height: 20px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border: 1px solid #c6c6c6;
    position: absolute;
    left: -42px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    background: #fff;
    z-index: 2;
    box-shadow: 0 0 10px #63848a;
    text-align: center;
    line-height: 20px;
    color:#fff
}

.listitem li a img {
    max-width: 70%;
    max-height: 70%
}

.listitem li a.active:after {
    background: #5CEA93;
    padding: 5px;
    border: 2px solid #5CEA93;
    box-shadow: 0 0 10px #5CEA93;
    color:#fff;
    text-align: center;
    line-height: 16px;
    padding: 0
}










.listitem li a.active {
    box-shadow: 0 8px 9px #63848a;
}

.listitem li a .active_check {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    box-shadow: 0 4px 4px #63848a;
    background: #fff;
    position: absolute;
    left: 15px;
    top: 15px;

}

.listitem li a.active .active_check {
    background: #5CEA93;
}

.listitem li a.active .active_check svg {
    margin: 0px 4px
}

.list_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 40px;
    text-align: center;
    background: #F5F8FD
}

.list_footer span {
    color: #c4c4c4;
    font-size: 10px;
}

.list_footer h6 {
    color: #363636;
    font-size: 12px;
    margin-bottom: 0;
}

.list_footer .col:first-child {
    border-right: 1px solid #e6e2e2
}


@media screen and (max-width:767px) {

    .listitem {
        flex-direction: row !important;
        flex-wrap: wrap;
        justify-content: space-around !important;
        left: 0;
        right: 0;
        align-content: baseline;
        background: #fff;
        max-height: 270px;
        box-shadow: 0 10px 10px rgba(47, 43, 113, 0.699);

    }

    .listitem li {
        margin-top: 50px !important;
    }

    .listitem li a {
        height: 108px;
        width: 108px;
    }

    .listitem li a img {
        max-width: 48px;
        max-height: 80px;
    }

    .listitem li a:after {
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        top: -30px
    }

}



@media screen and (min-width:768px) and (max-width:1199px) {
    .listitem li a {
        width: 190px;
        height: 190px;
    }

}