/* .App {
  text-align: center;
} */

.scroll-element {
  position: relative;
  background: #fff;
  margin-top: 90px;
}



.smalldevice { background: #F0F1F3; /* padding: 70px 0; */ }
.scrollelement { background: #fff; }

@media screen and (max-width: 767px) {
  .smalldevice {
    position: fixed !important;
    top: 0;
    z-index: 99;
    left: 0;
    right: 0;
  }
}


/*  //////////////////////////////////////////////////////////////////////////////////////////////////////////////  */
/*                                               #package.css                                                         */

.packborder {
  /* border-style: solid; */
  border-radius: 8px;
  /* border-color: rgb(40, 212, 40); */

}


.packageList {
  display: flex;
  background: #fff;
  /* padding: 10px 15px; */
  border-radius: 10px;
  color: #000;
  align-items: center;
  box-shadow: 0px 0px 10px #00000030;
  position: relative;
  /* overflow: hidden; */
  transition: 0.2s all;
  /* margin-bottom: 15px */
}

.packageInfo {
  flex: 75% 0;
  position: relative;
  z-index: 1;
  padding: 10px 15px;
  overflow: hidden;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px
}

.subPackage .packageInfo {
  flex: 71% 0;
  position: relative;
  z-index: 1;
  padding: 10px 15px;
  overflow: hidden;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px
}
.packageInfo h3 {
  font-size: 20px;
  color: #0e0e0e;
}

.packageInfo p {
  margin: 0;
}

.packageInfo h3 span {
  color: #734d9d;
  font-weight: 400;
  font-size: 20px;
}

.packageList .defaultBtn {
  box-shadow: 0px 0px 8px #ccc;
}

.packageInfo p {
  margin: 0;
  color: #888;
}

.packageDetails a {
  color: #82bd51;
  display: inline-block;
  font-size: 15px;
  border-radius: 28px;
}

.packageDetails .collapse {
  font-size: 15px;
  color: #464646;
}

.packageActon h4 {
  font-weight: bold;
  color: #734d9d;
  font-size: 26px;
  margin-bottom: 10px;
}

.packageInfo h3 {
  font-size: 22px;
  color: #0e0e0e;
  margin-bottom: 10px;
}

.packageActon {
  flex: 25% 0;
  text-align: center;
  border-left: 1px solid #82bd51;
}

.coachInfoWrap ul li.active a::before,
.coachInfoWrap ul li a:hover::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  position: absolute;
  top: auto;
  left: 50%;
  margin-left: -10px;
  opacity: 1;
  transition: all .3s ease;
  border-color: inherit transparent transparent transparent;
  bottom: -10px;
}

.packageList .packageInfo::after {
  content: "";
  background: #f8f2ff;
  width: 500px;
  height: 700px;
  position: absolute;
  left: -100px;
  bottom: -410px;
  z-index: -1;
  transform: rotate(404deg);
  border-radius: 175px;
}

.subPackage .packageList .packageInfo::after {
  content: "";
  background: #f8f2ff;
  width: 500px;
  height: 400px;
  position: absolute;
  left: -70px;
  bottom: -210px;
  z-index: -1;
  transform: rotate(404deg);
  border-radius: 175px;
}

.packageList:hover {
  box-shadow: 0px 0px 19px #00000030;
  transition: 0.2s all;
  /* transform: scale(1.01);
  transition: 0.2s all; */
}

.packageInfo .packageInfo {
  flex: 0 0 100%;
  width : 100%
}

.packageInfo .packageInfo .packagedropdownbutton {
  width: 120px
}

.packageInfo .packageInfo .packagedropdownbutton .dropdown h6 {
  color: #0e0e0e
}
.container_pk_item .packageInfo {
  padding-top: 36px;
}
.notApproved{
  position:relative;
}
.notApprovedH3{
  position: absolute;
  left: 50%;
  text-align: center;
  width: 700px;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  background: #767676e0;
  padding: 15px;
  border-radius: 15px;
}

.btnfix{
  margin-top: 19px;
}