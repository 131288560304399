/*
 * becomesponsor css File
*/

.becomesponsor {
    /* propertys here */
}


.banner_container {
    padding-top: 173px;
    padding-bottom: 80px;
}

.Becomesponsorfromarea h2 {
    font-size: 40px;
    color: #232323;
    font-weight: 600;
}


.fromto {   
    background: #aae9f2;
    padding: 5px 5px;
    border-radius: 54px;
}


.sponsorfrom {
    background: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 19px rgba(3, 59, 95, 0.15);
}

.form_block {
    padding: 15px;
}

.navTab .nav-item {
    margin-right: 20px;
    margin-bottom: 5px
}

.navTab .nav-item a svg {
    fill: #61686e;
    margin-right: 10px
}

.navTab .nav-item a svg {
    fill: #8a959d;
}

.navTab .nav-item a {
    background: #fff;
    border: 1px solid #8a959d;
    color: #8a959d;
    padding: 10px 55px;
    font-weight: 600;
    box-shadow: 0 0 10px rgba(0, 32, 84, .09)
}

.navTab .nav-item a.active svg {
    fill: #1aa3b9;
}

.navTab .nav-item a.active {
    background: #f8f7ff;
    border: 1px solid #1aa3b9;
    color: #1aa3b9;
}

.tabform .form-group {
    margin-bottom: .8rem;
}

.tabform .form-group label {
    margin-bottom: 3px;
    font-weight: 600;
}

.tabform input[type="text"],
.tabform input[type="email"],
.tabform input[type="number"],
.tabform input[type="date"],
.tabform input[type="tel"],
.tabform select,
.tabform input[type="password"]
{
    height: 50px;
    box-shadow: 0 0 10px rgba(0, 32, 84, .09);
    border: 1px solid #d7e2ea;
}

.tabform textarea {
    box-shadow: 0 0 10px rgba(0, 32, 84, .09);
    resize: none;
    border: 1px solid #d7e2ea;
}

.tabform input[type="text"]::placeholder,
.tabform input[type="email"]::placeholder,
.tabform input[type="number"]::placeholder,
.tabform input[type="date"]::placeholder,
.tabform input[type="tel"]::placeholder
.tabform textarea::placeholder {
    color: #7b8c98;
    font-size: 15px;
}


/* .tabformLeft {
    border-right: 1px solid rgba(0, 32, 84, .09)
} */

.tabform h5 {
    font-weight: 600;
    font-size: 18;
    color: #3e454b;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
}



.tab_desc p {
    color: #1aa3b9;
    font-size: 16px;
    margin: 0px 0px 10px;
    font-weight: 500;
}

.heading_block {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.heading_block h2 {
    font-size: 22px;
    font-weight: 600;
    color: #1aa3b9;
    margin-bottom: 0;
}

.heading_block .seperator {
    border-bottom: 1px solid #1aa3b9;
    flex: 1 1 auto;
    margin-left: 10px;
    margin-top: 0;
    margin-bottom: 0;
}

@media screen and (min-width:768px) and (max-width:991px) {
    .navTab .nav-item a {
        padding: 10px 18px;
    }
}

@media screen and (max-width:767px) {
    .navTab .nav-item a {
        padding: 10px 18px;
    }

    /* .tabformLeft {
        padding-right: 0 !important;
        border: none;
    }

    .tabformRight {
        padding-left: 0 !important;
    } */
}

@media screen and (max-width:534px) {
    .navTab .nav-item {
        width: 100%;
        margin-bottom: 5px;
    }
}

.email_box_set{
    flex: 1 1;
}