.countdown{
    /* width: 15vw; */
    display: flex;
    justify-content: space-around;

    gap: 10px;
    /* position: absolute; */
    /* transform: translate(0%,-50%); */
    top: 50%;
    left: 50%;
}
.box{
    /* width: 28vmin;
    height: 7vmin; */
    min-width: 80px ;
    height: 60px;
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    box-shadow: 15px 15px 30px rgba(0,0,0,0.5);
    font-size: 12px;
}

span.num{
    background-color: #ffffff;
    height: 100%;
    color: #032d4d;
    width: 100%;
    display: grid;
    place-items: center;
    font-weight: 600;
    font-size: 16px;
}
span.text{
    font-size: 0.8em;
    background-color: #03478a;
    display: block;
    width: 100%;
    text-align: center;
    padding: 0.1em 0;
    font-weight: 400;
    color:white
}
@media screen and (max-width: 1024px){
    .countdown{
        width: 18vw;
    }
    .box{
        height: 7vmin;
        width: 26vmin;
        font-size: 12px;
    }
}
@media screen and (max-width: 768px){
    .countdown{
        width: 90vw;
        flex-wrap: wrap;
        gap: 30px;
    }
    .box{
        width: calc( 50% - 40px );
        height: 7vmin;
        font-size: 14px;
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 380px){
    .countdown{
        gap: 15px;
    }
    .box{
        width: 100%;
        height: 7vmin;
        font-size: 8px;
        margin-bottom: 30px;
    }
    .span.text{
        font-size: 0.8em;
    }
}