/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.ck-editor__editable
{
  min-height:200px;
}

.loadertwo {position: fixed; left:0; top:0; right:0; bottom:0; background:rgba(0,0,0,.6); z-index: 9999; display: flex; align-items: center; justify-content: center; border-radius: 5px;}
.loaderText {position: absolute;top: 68%;
  font-size: 20px;
  color: black;}

 