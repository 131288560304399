/*
 * footer css File
*/

.footer {
    /* propertys here */
    position: relative;
}

.footer_top {
    background-color: #0e5684;
    /* background-image: url(../../images/footerBG.jpg); */
    background-position: center top;
    background-repeat: no-repeat;
    border-width: 0px 0px 0px 0px;
    border-color: #eae9e9;
    border-style: solid;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding:60px 0;
}
.footer__cap h2 {
    font-size: 2.5em;
    line-height: 1.5;
}
.footer__cap p {
    font-size: 1.2em;
}

.footer_top p {
    color: #fff
}

.footer_top h3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    color: #54EAFF
}

.footer_top ul {
    padding-left: 0;
    list-style: none;
}

.footer_top ul li {
    padding: 6px 0;
}

.footer_top ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
}

.footer_top ul li a:hover, .footer_top ul li a.active, .footer_top ul li a:active, .footer_top ul li a:focus {
    color: #54EAFF
}

.copy_tight {
    background: #1A1C1D;
    text-align: center;
    font-size: 13px;
    color: #1aa3b9;
    padding: 20px 0;
    letter-spacing: 2px;
}
.seperator { height:1px; background:#54EAFF; margin-top: 30px;  margin-bottom: 30px  }

.footer_top .micon { position: absolute;}
.footer_top .micon svg { font-size:14px; margin-right:20px; color:#fff; vertical-align: baseline;  }
.footer_top .heading .content-box-heading {
    font-size: 16px !important;
    line-height: 16px !important;
    font-weight: 500;
    padding-left: 33px;
    color: #fff;
}
.footer_top .content-container {
    padding-left: 33px;
    color: #ccd0d1;
}

.footer_top .content-container p {margin-bottom: 0; color:#ccd0d1}


.footer_top .form-control{
    border: 1px solid #22C3DD;
    border-radius: 5px;    
    min-height: 55px;
    font-size: 13px;
}
.footer_top .form-control::placeholder {
    color: #fff
}

.footer_top .form-control:focus,
.footer_top .form-control {
    background: transparent;
    color: #fff;
    box-shadow: none;
}


.footer_navigation h3 { color:#fff}
.footer_navigation .seperator_footer {
    position: relative;
    flex-grow: 1;
    margin-left: 5px;
}
.footer_navigation .seperator_footer .sep-solid {
    position: relative;
    display: block;
    width: 100%;
    border-style: solid;
    border: 0 solid #e7e6e6;
    box-sizing: content-box;
    border-color: #22c3dd; 
    border-bottom-width: 1px;
}

.footer_nav .nav-link {padding-left:0 !important; padding-right: 2em !important; font-size: 16px; color:#fff !important; font-weight: 500; text-transform: none;}
.footer_nav .nav-link:hover {color:#22C3DD !important}