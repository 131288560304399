.signuptext{
    margin: 0 auto;
    /* background: #e3e3e3; */
    border-radius: 10px;
    text-align: center;
}

.images_partts{
    max-width: 450px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin: 23px auto 5px;
    padding: 20px;
    
    
}
.steps .step {
    font-weight:600;
    font-size: 18px;
    line-height: 23px;
    width: 21.33%;
    display: inline-block;
    padding: 0;
    text-align: center;
}
.img_box{
    width:100px;
    text-align: center;
    background: #aae9f2;
    border-radius: 100%;
    height: 100px;
    padding: 14px;
    position: relative;
}
.img_box:before{
    content: "";
    position: absolute;
    left: -5px;
    top: -5px;
    background: url(../../../Cmponents/images/rounder_after_icon.png) no-repeat;
    width: 110px;
    height: 110px;
    background-size: 100% 100%;
}

.img_box:after{
    content: "";
    position: absolute;
    right: -53%;
    top: 44%;
    background: url(../../../Cmponents/images/right_arrow_icon.png) no-repeat;
    width: 67px;
    height: 23px;
    background-size: 100% 100%;

}

.img_box:nth-child(3):after{
    content: none;
}
.img_inner_box{
    background: #00bcd5;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.img_inner_box label{
    display: block;
    color: #fff;
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.sign_up_para {padding: 5px 0 20px;}
.signuptext p{
    text-align: center;
    color: #0E5684;
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 0;
}

.img_inner_box img{
    width: 26px;
}
.fix_logo_align{
    height: 70px;
}
.container-max{
    max-width: 581px;
    width: 100%;
    margin: 15px auto;
}
.arrow_fix{
    height: 32px;
    margin: 20px;

}