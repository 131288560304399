.email_box{
    max-width: 64%;
    width: 100%;
}
.align_fix{
    margin: 0px 0px 0px 15px;
}

.otp_btn_btn{
    height: 40px;
    margin-left: 27px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 28px;
    /* background: #00bcd4; */
    /* color: #fff; */
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    background: #00bcd4;
    transition: all 0.3s ease;
    /* box-shadow: 0 5px 3px rgb(0 0 0 / 40%); */
    border-radius: 8px;
    border: 1px solid #1AA3B9;
    text-transform: uppercase;
}

.verified_btn{
    position: relative;
    height: 40px;
    margin-left: 27px;
    padding-left: 26px;
    padding-right: 26px;
    margin-top: 28px;
    background: green;
    color: #fff;
    transition: all 0.3s ease;
    /* box-shadow: 0 5px 3px rgb(0 0 0 / 40%); */
    border-radius: 8px;
    border: 1px solid green;
    text-transform: uppercase;
}
.error_btn_fix{
    position: relative;
    height: 40px;
    /* margin-left: 27px; */
    /* padding-left: 30px;
    padding-right: 30px;
    margin-top: 28px; */
    background: #D4403A;
    color: #fff;
    transition: all 0.3s ease;
    /* box-shadow: 0 5px 3px rgb(0 0 0 / 40%); */
    border-radius: 8px;
    border: 1px solid #D4403A;
    text-transform: uppercase;
}
.verified_btn::before{
    content: url(.././images/right_sign.png);
    border-radius: 8px;
    border: 1px solid green;
    text-transform: uppercase;
    transition: all 0.3s ease;

}
.verified_btn:hover
 {
  background: green !important;
  color: #fff !important; 
}
/* .valid_otp_btn,.otp_btn_btn:hover
 {
  background: #17b2d8 !important;
  color: #fff !important;
  border-color: #17b2d8 !important;  
} */
.error_btn_fix:hover{
    color: #fff !important;
}
.valid_otp_btn{
    height: 50px !important;
    /* margin-left: 10px;
    padding-left: 28px;
    padding-right: 28px;
    margin-top: 28px; */
    /* background: #00bcd4; */
    /* color: #fff; */
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    /* background: #00bcd4; */
    transition: all 0.3s ease;
    /* box-shadow: 0 5px 3px rgb(0 0 0 / 40%); */
    border-radius: 8px;
    /* border: 1px solid #1AA3B9; */
    text-transform: uppercase;
}