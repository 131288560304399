.cart_partts{
    padding-top: 90px;
}

.cart_partts_inner h2{
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 40px;
    padding-top: 40px;
    position: relative;
    z-index: 1;
}

.cart_left_top{
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 12px;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
}

.cart_left_inner_left{
    max-width: 70%;
    width: 100%;
    display: flex;
    font-size: 16px;
}


.cart_left_inner_box{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    max-width: 150px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
}
.cart_left_inner_text{
    margin-left: 15px;
}
.cart_left_inner_text h3{
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}

.select_box{
    height: 28px;
    background: #FFFFFF;
border: 1px solid #C1C1C1;
border-radius: 4px;
width: 110px;
line-height: 28px;
padding: 0px;
font-size: 12px;
}

.cart_left_inner_text p{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #757474;
    margin-top: 20px;

}

.cart_left_inner_right .org_price{
    font-weight: 700;
    font-size: 24px;
    line-height: 14px;
    color: #1AA3B9;
    text-align: right;
    margin-bottom: 10px;
}

.cart_left_inner_right label{
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    text-decoration-line: line-through;
    color: rgba(77, 77, 77, 0.44);
    text-align: right;
    margin-bottom: 10px;
    display: block;
}

.cart_left_inner_right .off_price{
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #1AA3B9;
    text-align: right;
}

.delete_button{
    text-align: right;
    margin-top: 10px;
}

.cart_left_bottom{
    /* margin-top: 30px; */
}

.all_in_one_pack{
    background: #0e6397;
    border-radius: 12px;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all_in_one_pack:hover {
    /* cursor: pointer; */
    background: #024670;
}

.all_in_text h2{
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #FFFFFF;
    margin-bottom: 15px;
    padding-top: 0;

}

.all_in_text h3{
    font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 24px;
color: #22C3DD;
margin-bottom: 0px;
}

.get_it_button{
    background: #1AA3B9;
    border: none;
    border-radius: 8px;
    display: inline-block;
    height: 42px;
    line-height: 42px;
    padding: 0 20px;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.get_it_button:hover,.get_it_button:focus,.get_it_button:active{
    background: #1892a5 !important;
    border: none;
    border-radius: 8px;
    display: inline-block;
    height: 42px;
    line-height: 42px;
    padding: 0 20px;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
    box-shadow: none !important;
    outline: none !important;
}


.order_summery{
    /* background: #E3FBFF;
    border-radius: 6px;
    padding: 30px; */

    /* content: ""; */
    /* position: absolute; */
    left: 0;
    top: 0;
    background-image: url(../../../Cmponents/Jobseeker/images/overlayyy.jpg);
    background-position: center bottom;
    background-size: cover;
    /* opacity: 0.80; */
    /* width: 100%; */
    /* height: 100%; */
    padding: 30px;
    border: 1px solid #0E5684;
    border-radius: 6px;
    z-index: -1; 
}


.order_summery h3{
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
     color: #0E5684;
}

.order_summery p.item_number{
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #0E5684;
    margin-top: 20px;
}

.border1{
    width: 100%;
    height: 1px;
    background:  #1D91A4;
    margin-top: 30px;
    margin-bottom: 20px;
}

.sub_total_partts{
    display: flex;
    justify-content: space-between;
}

.sub_total_partts h4{
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #0E5684;
}

.sub_t_amnt h5{
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #0D73B5;
}

.sub_t_amnt strike{
    display: block;
    text-align: right;
    font-size: 18px;
    color: #B1B1B1;
}

.saved{
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: #266C77;
    text-align: center;
    margin-top: 18px;
}
.saved span{
    color: #1880C3;
    font-size: 18px;
}

.taxes{
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: #505050;
    margin-top: 20px;
    text-align: center;
}

.form-group1 .check_input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-group1 label {
    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    text-decoration-line: underline;
    color: #217280;
  }
  
  .form-group1 label:before {
    content:'';
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #0079bf;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .form-group1 input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 7px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

.form-group1{
    margin-top: 30px;
}
.promo_field{
    /* margin-top: 10px; */
    display: flex;
    flex-wrap: wrap;
}
.promo_field input[type="text"]{
    background: #fafefe;
    border: 1px solid #80B5BE;
    height: 29px;
    line-height: 22px;
    padding: 0 10px;
    font-weight: 500;
    font-size: 12px;
    color: #4DAEBE;
    width: 100%;

}

.promo_field input[type="text"]::placeholder{
    color: #4DAEBE;
}
.submit_button{
    margin-top: 20px;
}
.submit_button button{
    background: #0E5684;
border-radius: 6px;
height: 40px;
line-height: 40px;
display: block;
width: 100%;
border: 0px;
font-weight: 500;
font-size: 13px;
color: #fff;
}

@media screen and (max-width:991px){
    .order_summery{
        margin-top: 30px;
    }
}

@media screen and (max-width:767px){

    .cart_left_top{
        flex-wrap: wrap;
    }
    .cart_left_inner_left, .cart_left_inner_right{
        max-width: 100%;
        width: 100%;
    }
    .all_in_one_pack{
        flex-wrap: wrap;
    }
}

@media screen and (max-width:575px){
    .cart_left_inner_left, .cart_left_inner_right{
        flex-wrap: wrap;
    }
    .all_in_text h2{
        line-height: 34px;
    }
}
.logo-list{
    background: white;
    border-radius: 15px;
    margin-top: 15px;
    padding: 15px;
}
.logo-list .col-md-2{
    display: flex;
    align-items: center;
}
.promo_code_apply{
    padding: 3px 5px;
    background:#1AA3B9;
}
.promo_code_apply:hover{
    padding: 3px 5px;
    /* margin: 7px 0px 0px 33px; */
    background:#1AA3B9;
    color: #FFFFFF;
}