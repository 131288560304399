/*
 * home css File
*/

.home {
    /* propertys here */
}


.banner_container_home {
    padding-top: 260px;
}

.bannertop {
    background: url('../../images/banner.jpg') top center no-repeat;
    background-size: cover;
    min-height: 636px;
    color: #fff;
    /* background: #5238da;
    background: -moz-linear-gradient(40deg, #5238da 16%, #fd866d 77%, #fd866d 77%, #fd866d 81%);
    background: -webkit-linear-gradient(40deg, #5238da 16%, #fd866d 77%, #fd866d 77%, #fd866d 81%);
    background: linear-gradient(40deg, #5238da 16%, #fd866d 77%, #fd866d 77%, #fd866d 81%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5238da', endColorstr='#fd866d', GradientType=1);
    -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5238da', endColorstr='#fd866d', GradientType=1); */

}

.bannertop h2 {
    font-size: 50px;
}

.breadcrumb {
    background: transparent;
    padding: 0;
    margin-bottom: 5px;
}

.breadcrumb li a,
.breadcrumb li.active {
    color: #fff;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: ">";
    color: #fff
}

.view_details_button {
    background: #fff;
    color: #5238da;
    font-size: 16px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 10px 25px;
}

.helppeoplr_container .row {
    background: url(../../images/help_people_bg.png) center no-repeat;
}

.helppeoplr_container .card {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    width: 200px;
    height: 200px;
    border: none;
}

.helppeoplr_container .card h3 {
    font-weight: 700;
    font-size: 44px;
    color: #2c2c51;
}

.helppeoplr_container h2 {
    font-size: 37px;
    color: #2c2c51;
    font-weight: 600;
}


.Howdoseitwork h2 {
    font-size: 37px;
    color: #2c2c51;
    font-weight: 600;
}

.Howdoseitwork .video_frame {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    box-shadow: 0 0 28px rgba(5, 22, 51, 0.3);
    overflow: hidden;
}


.becomesponsorblock,
.getsponsorshipblock {
    background: #fff;
}

.becomesponsorblock_right h3,
.getsponsorshipblock_left h3 {
    margin-bottom: 0;
    color: #24273d;
    font-weight: 600;
    font-size: 37px;
}

.becomesponsorblock_right span,
.getsponsorshipblock_left span {
    margin-bottom: 0;
    color: #24273d;
    font-size: 16px;
}

.becomesponsorblock_right p,
.getsponsorshipblock_left p {
    margin-top: 15px;
    color: #484a56;
    font-size: 16px;
    line-height: 29px;
}

.icon {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    width: 60px;
    height: 60px;
}

.icon svg {
    margin: 10px;
}

.icon.red {
    background: #ffe0e0;
}

.icon.green {
    background: #edffe0;
}

.icon.dgreen {
    background: #e0fff4;
}

.icon.pink {
    background: #ede0ff;
}


.icon.red svg {
    fill: #ff4d4d;
}

.icon.green svg {
    fill: #59af1b;
}

.icon.dgreen svg {
    fill: #17bf83;
}

.icon.pink svg {
    fill: #6f26d3;
}

.bsdesc {
    padding-left: 10px;
}

.bsdesc p {
    margin: 0;
    line-height: inherit;
    font-size: 14px;
}

.gs__list {
    list-style: none;
    padding: 0;
}
.gs__list li {
    float: left;
    width: 50%;
    margin-bottom: 15px;
    padding-left: 20px;
    padding-right: 10px;
    position: relative;
}
.gs__list li:before {
    content: "";
    background: url(../../images/check.png);
    position: absolute;
    width: 15px;
    height: 11px;
    left: 0;
    top: 5px;
    display: block;
}

.causeswesupport h2 {
    margin-bottom: 0 !important;
    color: #24273d;
    font-weight: 600;
    font-size: 37px
}

.causeswesupport .card,
.sponsors .card {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #004887;
}

.causeswesupport .col-md-4 {
    margin-top: 15px;
    margin-bottom: 15px;
}
.causeswesupport .card .card_body  {/* border-top-left-radius: 15px; border-top-right-radius: 15px; */ background: #fff;}
.causeswesupport .card .card_body h3 { color:#24273d; font-size: 22px; font-weight: 600;}




.belovedsponsors .belovedsponsorshead h2 {
    font-size: 40px;
    color: #232323;
    font-weight: 600;
}

.belovedsponsors .belovedsponsorshead p {
    color: #535f68;
    font-size: 16px;
}

.sponsors .card h6 {
    font-size: 16px;
    color: #3e454a;
    font-weight: 600;
    margin-top: 15px;
}

.subscribeNewsletter {
    background:#0e5684;
}

.subscribeNewsletter .subscribeleft {
    border-right: 1px solid #116ca7;
}

.subscribeNewsletter .subscribeleft h2 {
    font-size: 37px;
    font-weight: 600;
    color: #fff;
}

.subscribeNewsletter .subscribeleft p {
    color: #fff;
}

.subscriberight form input {
    background: transparent;
    height: 46px;
}
.subscriberight form input:focus {
    color: #fff;
    background: transparent;
}

.subscriberight form input::placeholder,
.subscriberight form input {
    color: #fff;
}

@media screen and (max-width:991px) {
    .helppeoplr_container .card { 
        max-width: 180px;
        height: 180px;
        flex: 0 0 40%;
        margin-top: 15px;
    }
    .helppeoplr_container .row
    {
        background: transparent;
    }
    .sponsors .row .col {
        flex: 0 0 160px;
        max-width: 160px;
        margin-top: 15px;
        
    }
    .navbar-light .navbar-toggler {
        color: #fff;
        border-color: #fff;
    }
}
.job_l{
    font-size: 24px;
    font-weight:700;
    /* text-align: right; */
    margin-left: 95px;

}
.job_r{
    font-size: 24px;
    font-weight:700;
    text-align: left;
}
.sp_btn_fix_l{
    float: right;
}
.left_content{
    border-right: 1px solid #fff;
}