/*
 * getsponsor css File
*/
.navTab .nav-item a {padding:10px 12px;}
.getsponsor {
    /* propertys here */
}

.banner_container {
    padding-top: 173px;
    padding-bottom: 80px;
}


.Getsponsorfromarea h2 {
    font-size: 40px;
    color: #232323;
    font-weight: 600;
}

.sponsorfrom {
    background: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 19px rgba(3, 59, 95, 0.15);
}

.form_block {padding: 15px;}

.navTab .nav-item {margin-right:20px;}
.navTab .nav-item a svg {fill:#61686e; margin-right:10px}
.navTab .nav-item a svg { fill:#8a959d; }
.navTab .nav-item a {background:#fff; border:1px solid #8a959d; color:#8a959d; padding:10px 12px; font-weight: 600; box-shadow: 0 0 10px rgba(0,32,84,.09)}

.navTab .nav-item a.active svg {fill:#1aa3b9;}
.navTab .nav-item a.active {
    background: #f8f7ff;
    border: 1px solid #1aa3b9;
    color: #1aa3b9;
}
.tabform .form-group { margin-bottom:.8rem; }
.tabform .form-group label { margin-bottom:3px; font-weight: 600; }
.tabform input[type="text"], .tabform input[type="email"], .tabform select, .tabform .custom-file label {height:50px; box-shadow: 0 0 10px rgba(0,32,84,.09); border: 1px solid #d7e2ea; line-height: 34px}
.tabform .custom-file label {color:#7b8c98}
.tabform .custom-file-label::after {height:100%; line-height:34px; width:130px; text-align: center; }
.tabform textarea {box-shadow: 0 0 10px rgba(0,32,84,.09); resize: none; border: 1px solid #d7e2ea;}

.tabform input[type="text"]::placeholder, .tabform input[type="email"]::placeholder, .tabform textarea::placeholder { color:#7b8c98; font-size: 15px; }


/* .tabformLeft {border-right:1px solid rgba(0,32,84,.09)} */

.tabform h5 {font-weight: 600; font-size: 18; color:#3e454b;}

.tabform .custom-file, .tabform .custom-file input[type="text"] { height:50px }


.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.form-row > .col, .form-row > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
}


@media screen and (min-width:768px) and (max-width:991px) {
    .navTab .nav-item a {padding:10px 18px;}
}

@media screen and (max-width:767px) {
    .navTab .nav-item a {padding:10px 18px;}
    /* .tabformLeft {padding-right: 0 !important; border: none;}
    .tabformRight {padding-left: 0 !important; } */
}

@media screen and (max-width:534px) {
    .navTab .nav-item {width:100%; margin-bottom: 5px;}
}